// 结算账单列表：http://219.128.52.2:18765/doc.html#/haolv-consumer/t-us-company-credit-controller/billListUsingPOST
const __request = require(`./__request/__request_contentType_json`);
const consumer_credit_billList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/credit/billList',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_credit_billList;