import moment from 'moment';
// 已绑定银行卡
import credit_billList from "@/lib/data-service/haolv-default/consumer_credit_billList.js";
export default {
  components: {
    
  },
  data() {
    return {
      loading: false,
      form: {
        currentPage: 1,
        pageSize: 10,
        // 还款日期
        refundStartDate: '',
        refundEndDate: '',
        // 账单日期
        billStartDate: '',
        billEndDate: '',
      },
      refundDate: null,
      billDate: null,
      pager: {
        total: 0,
        pageSizes: [ 10, 15, 20, 25, 30],
      },
      list: [],
      pickerOptions: {
        disabledDate(time) {
          const now = Date.now()
          const threeYearsAgo = moment(now).add(-3, 'years')._d;
          return time.getTime() > now || time.getTime() < threeYearsAgo;
        },
      },
    };
  },
  computed: {
  },
  methods: {
    req_list_datas () {
      if (this.loading) return;
      this.loading = true;
      this.list = [{}];
      credit_billList(this.form).then((res) => {
        this.list = res.datas.list;
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
      })
    },
    search () {
      this.form.currentPage = 1;
      this.req_list_datas();
    },
    change_currentPage (currentPage) {
      this.form.currentPage = currentPage;
      this.req_list_datas();
    },
    change_pageSize (pageSize) {
      this.form.pageSize = pageSize;
      this.change_currentPage(1);
    },
    to_detail (billDate) {
      this.$router.push({
        name: 'admin-finance-credit-have-bill',
        query: {
          billStartDate: moment(billDate).add(-1, 'months').format('YYYY-MM-DD'),
          billEndDate: billDate,
        }
      });
    },
    to_pay_back (billId) {
      this.$router.push({
        name: 'admin-finance-credit-repayments',
        query: {
          billId
        }
      });
    },
  },
  created() {},
  mounted() {
    
  },
  activated() {
    this.req_list_datas();
  },
  deactivated() {},
  destroyed() {},
  watch: {
    refundDate (val) {
      const form = this.form;
      if (val) {
        form.refundStartDate = val[0];
        form.refundEndDate = val[1];
      } else {
        form.refundStartDate = '';
        form.refundEndDate = '';
      }
    },
    billDate (val) {
      const form = this.form;
      if (val) {
        form.billStartDate = val[0];
        form.billEndDate = val[1];
      } else {
        form.billStartDate = '';
        form.billEndDate = '';
      }
    }
  },
};
